<script setup lang="ts">
import { ref, onMounted } from "vue"
import { search } from 'pn-design-assets/pn-assets/icons'


const props = defineProps<{
  custNo?: string
  refId?: string
}>()

type Shipment = {
  shipmentId: string
}

const customerNumber = ref("")
const refId = ref("")
const errorText = ref("")
const shipments = ref<Shipment[]>([])

onMounted(()=>{
  if(props.custNo){
    customerNumber.value = props.custNo
  }
  if(props.refId){
    refId.value = props.refId
  }
  searchByRef()
})

const handleResponse = (
  response: any
)=>{
  const contentType = response?.headers?.get("content-type");
  if(contentType === "application/json" && response.status === 200){
    response.json()
      .then((data:any) => shipments.value = data?.TrackingInformationResponse?.shipments ?? [])
      .catch(handleError)
  } else {
    handleError("invalid response")
  }
}

const handleError = (e:any)=>{
  console.log(e)
  errorText.value = "Not found"
}

const searchByRef = () => {
  if(!refId.value || !customerNumber.value){
    return
  }
  var domain = "atapi2.postnord.com"
  var path = "rest/shipment/v2/trackandtrace/findByReference.json"
  var url = `https://${domain}/${path}?referenceValue=${refId.value}&customerNumber=${customerNumber.value}&locale=en`
  fetch(url, {
    headers: {
      "x-bap-key": "web-ncp",
      "content-type": "application/json"
    }
  }).then(handleResponse).catch(handleError)
}
</script>

<template>
  <div class="landingView-container">
    <div class="search-component">
      <pn-input label="Reference" v-model="refId"/>
      <pn-input label="Customer number" v-model="customerNumber" />
      <pn-button :icon="searchByRef" @click="search"></pn-button>
      <p v-show="errorText">{{ errorText }}</p>
    </div>
    <ul>
    <li v-for="s in shipments" :key="s.shipmentId" >
      <a :href="`/?id=${s.shipmentId}`">{{s.shipmentId}}</a>
    </li>
  </ul>
  </div>
</template>


<style lang="scss" scoped>
.landingView-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-x: hidden;

  .search-component {
    max-width: 700px;
    align-self: center;
    justify-self: end;
    padding-left: 2em;
  }

  .illustration-container {
    display: flex;
    position: relative;
    flex-shrink: 0;
    justify-self: center;
    .background-fill {
      position: absolute;
      align-self: center;
      top: 0;
      left: 99%;
      background: $blue700;
      height: 100%;
      width: 50vw;
    }
  }
}
@media only screen and (max-width: 880px) {
  .landingView-container {
    display: flex;
    flex-flow: column nowrap;
    overflow-x: visible;

    .search-component {
      margin-top: 0em;
      margin-left: 0em;
      margin-right: 0em;
      margin-bottom: 0em;
      padding-left: 0em;
    }

    .illustration-container {
      align-self: flex-end;
      margin-top: 2em;

      img {
        width: 33rem;
      }
      .background-fill {
        display: none;
      }
    }
  }
}
</style>
